import axios from 'axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'

export namespace Config {
  const E_PAY_URL = 'https://epay.kkb.kz/jsp/process/logon.jsp' // - URL куда отправлять данные оплаты

  let config: Config = {
    version: '1.0.0',
    symbolVersion: 'wooden block',
    release: '0',
    isInit: false,
    isAdmin: null,
    isDev: null,
    isTest: null,
    baseUrl: 'https://newdev.mybuh.kz',
    ePayUrl: E_PAY_URL,
    publicFolderUrl: process.env.PUBLIC_URL ?? '',
    promo: null,
  }

  // console.log(`V. ${config.version} "${config.symbolVersion}"; Release redesign ${config.release}`)

  export const getConfig = () => {
    return config
  }

  export const setConfig = (newConfig: Partial<Config>) => {
    config = _.merge(config, newConfig)
  }
  export const init = async (devParams?: {isAdmin?: boolean}) => {
    // load plugins
    dayjs.extend(customParseFormat)
    dayjs.extend(duration)
    dayjs.extend(utc)
    dayjs.extend(timezone)

    /*
     * Определяем на каком домене сервер. Если локалхост, то `cabinetdev.mybuh.kz`
     * */
    const hostNames = [
      'cabinetdev.mybuh.kz',
      'cabinettest.mybuh.kz',
      'cabinet.mybuh.kz',
      'regi.mybuh.kz',
      'regidev.mybuh.kz',
      'regitest.mybuh.kz',
      'newdev.mybuh.kz',
      'newregi.mybuh.kz',
      'v3dev.mybuh.kz',
    ]
    let baseUrl = `https://${window.location.hostname}`

    if (!hostNames.includes(window.location.hostname)) {
      // baseUrl = 'https://regidev.mybuh.kz' // for admin
      // baseUrl = 'https://newdev.mybuh.kz'
      baseUrl = 'https://cabinetdev.mybuh.kz'
      // baseUrl = 'https://cabinettest.mybuh.kz'
      // baseUrl = 'https://v3dev.mybuh.kz'
    }

    if (devParams?.isAdmin) {
      baseUrl = 'https://regidev.mybuh.kz' // for admin
      // baseUrl = 'https://regitest.mybuh.kz' // for admin
    }

    const serverConfig: {
      IS_ADMIN: boolean
      IS_DEVELOPMENT: boolean
      IS_TEST: boolean
      promo: Config['promo']
    } = await axios({
      method: 'POST',
      timeout: 0,
      url: baseUrl + '/ajax.php',
      withCredentials: true,
      headers: {'Content-type': 'application/x-www-form-urlencoded'},
      data:
        'params=' +
        encodeURIComponent(
          JSON.stringify({
            modul: 'app_config',
            oper: 'app_settings',
          })
        ),
    }).then(res => res.data.data)

    setConfig({
      isAdmin: devParams?.isAdmin ?? serverConfig.IS_ADMIN,
      isTest: serverConfig.IS_TEST,
      // TODO: find out why isDev === null in dev mode
      // isDev: serverConfig.IS_DEVELOPMENT,
      isDev: process.env.REACT_APP_BUILD_TYPE === 'development', // temp fix
      baseUrl: baseUrl,
      isInit: true,
      promo: serverConfig.promo,
    })

    return true
  }

  type Config = {
    version: string // Application version. Need to change after deploy. Set it into commit message
    symbolVersion: string
    release: string
    isInit: boolean // is config init
    isAdmin: boolean | null // shows what domain Application hosted: regi(?dev/test).mybuh.kz
    isDev: boolean | null //                                       (regi/cabinet)dev.mybuh.kz (for localhost too)
    isTest: boolean | null //                                       (regi/cabinet)test.mybuh.kz
    baseUrl: string // baseUrl for API requestes
    ePayUrl: string // url for aquaring
    publicFolderUrl: string // `./public/` folder
    promo: {
      discount: number
      code: string
      label: string
      date_start: string
      date_end: string
      subscriptions: number[]
    } | null
  }
}
